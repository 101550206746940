import React, { useEffect, useState } from "react"
import * as S from "./styled"
import { Input, SuffixContainerComponent } from "../../components/Input"
import { useLoginHandlers } from "./useLogin"
import { Checkbox } from "../../components/Checkbox"
import { isInIframe } from "../../util/isInIframe"
import { Trans, useTranslation } from "react-i18next"

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const { register, errors, onSubmitHandler, wrongCode } = useLoginHandlers()
  const [rendered, setRendered] = useState<boolean>(false)
  useEffect(() => setRendered(true), [])
  return (
    <S.Wrapper>
      {rendered && !isInIframe() && (
        <S.LogoWrapper>
          <S.Logo />
        </S.LogoWrapper>
      )}
      <S.Background>
        <S.Container onSubmit={onSubmitHandler} as={rendered ? "form" : "div"}>
          <S.Title>{t("welcomeText")}</S.Title>

          <Input
            placeholder={t("enterYourTechroomId")}
            id="code"
            name="code"
            autoFocus
            suffix={
              <SuffixContainerComponent as="button" type={"submit"}>
                <S.ArrowIcon />
              </SuffixContainerComponent>
            }
            ref={register({ required: true })}
          />
          <S.Error>
            {wrongCode
              ? t("theProvidedIdIsInvalid")
              : errors.code && t("thisFieldIsRequired")}
          </S.Error>

          <S.AcceptTerms>
            <Checkbox
              ref={register({ required: true })}
              id="acceptsTerms"
              name="acceptsTerms"
            />
            <label htmlFor="acceptsTerms">
              <span>
                <Trans i18nKey="acceptTermsText" as="span" />
              </span>
            </label>
          </S.AcceptTerms>
          <S.Error>
            {errors.acceptsTerms && t("acceptTermsAndConditions")}
          </S.Error>
        </S.Container>
      </S.Background>
    </S.Wrapper>
  )
}
