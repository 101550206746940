import React, { useContext, useEffect } from "react"
import { DefaultBg } from "../components/Backgrounds"
import { EventContext } from "../context/EventContext"
import { navigate } from "gatsby"
import { Login } from "../layout/Login"
import { WithAppContext } from "../context/AppContext"

const Page: React.FC = props => {
  // @ts-expect-error
  const locale = props.pageContext?.locale || "en"
  const eventContext = useContext(EventContext)
  useEffect(() => {
    if (eventContext.status === "success") {
      navigate(`/${locale}/event/#${eventContext.slug}`)
    }
  }, [eventContext.status])

  return (
    <WithAppContext>
      <main style={{ height: "100%" }}>
        <DefaultBg />
        <Login />
      </main>
    </WithAppContext>
  )
}

export default Page
